<template>
  <v-container fluid>
    <v-card-title>메세지 발송하기</v-card-title>
    <v-card flat outlined>
      <Notification type="pushMessage" />
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Notification from '@/components/Notification.vue'

export default defineComponent({
  components: { Notification },
})
</script>
